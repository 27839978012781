import OrdersFilters from "types/orders-filters";

export default (url: URL, filters: OrdersFilters,page:number,excludeCustomerNumber?:true) => {
  const {
    range,
    storeIds,
    orderTypes,
    paymentMethods,
    tags,
    ratings,
    customerNumber,
    pageSize,
  } = filters;

  url.searchParams.set("page", String(page));
  url.searchParams.set("pageSize", String(pageSize));

  url.searchParams.set("from", String(range.start.unix() * 1000));
  url.searchParams.set("to", String(range.end.unix() * 1000));
  url.searchParams.set("storeIds", storeIds.join(","));
  url.searchParams.set("orderTypes", orderTypes.join(","));
  url.searchParams.set("paymentMethods", paymentMethods.join(","));
  url.searchParams.set("tags", tags.join(","));
  url.searchParams.set("ratings", ratings.join(","));
  if (customerNumber && !excludeCustomerNumber) url.searchParams.set("customerNumber", customerNumber);

  return url;
};
