import { motion, AnimatePresence, HTMLMotionProps } from "framer-motion";
import React, { createContext, useEffect, useRef, useState } from "react";
import Button from "UI/Button";
import Icon from "UI/Icon";
import filterStyles from "../Transactions.module.css";
import styles from "./TransactionsAddresses.module.css";

type Props = {
  filterComponent: JSX.Element;
  children: JSX.Element | JSX.Element[];
  hasValue?:boolean
  vertical?:boolean
};
type FilterContextProps = {
  close:() => void
}

export const FilterContext = createContext<FilterContextProps>({close:() => {}});

const TransactionFilter = ({ filterComponent, children,hasValue,vertical }: Props) => {
  const [opened, setOpened] = useState(false);
  const close = () => setOpened(false)
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // Обработчик клика для всего документа
    const handleClickOutside = (event: MouseEvent) => {
        // Если клик произошел вне элемента, на который ссылается ref
        if (ref.current && !ref.current.contains(event.target as Node)) {
          setOpened(false); // Закрываем компонент или меняем состояние
        }
    };

    // Добавляем слушатель события
    document.addEventListener('mousedown', handleClickOutside);

    // Очищаем слушатель при размонтировании компонента
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, []);
const animation = vertical ? verticalfilterAnimation : filterAnimation
  return (
    <div ref={ref} className={filterStyles.filterButtonContainer}>
      <Button
        className={`${filterStyles.filterButton}
          ${opened && filterStyles.opened}
          ${hasValue && filterStyles.hasValue}
          `}
        onClick={() => setOpened(!opened)}
      >
        {children}
        <motion.i animate={{ rotate: opened ? 0 : 180 }} initial={false}>
          <Icon icon="arrowTop" className={filterStyles.strokeFill} />
        </motion.i>
      </Button>

        <FilterContext.Provider value={{close}}>
          <AnimatePresence>
              {opened && (
                <motion.div
                  {...animation}
                  key={Date.now()}
                  className={filterStyles.filterButtonBody}
                  style={vertical ? {transform:'translateY(-100%)'} : {}}
                >
                  {filterComponent}
                </motion.div>
              )}
          </AnimatePresence>
        </FilterContext.Provider>
    </div>
  );
};
const filterAnimation: HTMLMotionProps<"div"> = {
  initial: {
    opacity: 0,
    bottom: -50,
  },
  animate: {
    opacity: 1,
    bottom: -10,
  },
  exit: {
    opacity: 0,
    bottom: -50,
  },
  transition: { duration: 0.2, ease: "circInOut" },
};
const verticalfilterAnimation: HTMLMotionProps<"div"> = {
  initial: {
    opacity: 0,
    top: -50,
  },
  animate: {
    opacity: 1,
    top: -10,
  },
  exit: {
    opacity: 0,
    top: -50,
  },
}
export default TransactionFilter;
