import publishStory from "api/stories/publish-story";
import unpublishStory from "api/stories/unpublish-story";
import Story from "types/story";
import { notification } from "./use-notifications";
import archiveStory from "api/stories/archive-story";
import unarchiveStory from "api/stories/unarchive-story";
import deleteStory from "api/stories/delete-story";
import validateStory from "utils/validate-story";

export default (story:Story,onSuccess?:() => any) => {
    const handleUnpublish = async () => {
        const res = await unpublishStory({storyIds:[story.id]})
        if (!res) notification("Не удалось снять с публикации",'warning');
        else if ("description" in res) notification(`Ошибка при снятии с публикации:\n${res.description}`,'warning');
        else {
            if(onSuccess) await onSuccess()
            notification('Снято с публикации')
        }
    }

    const handlePublish = async (skipValidate?:boolean) => {
        if(!skipValidate) {
            const error = validateStory(story)
            if(error?.length) {
                notification(`Новость не опубликована:\n${error}`,'warning');
                return
            }
        }

        const res = await publishStory({storyIds:[story.id]})
        if (!res) notification("Не удалось опубликовать",'warning');
        else if ("description" in res) notification(`Ошибка при публикации:\n${res.description}`,'warning');
        else {
            if(onSuccess) await onSuccess()
            notification('Новость опубликована','success')
        }
    }

    const handleArchive = async () => {
        const res = await archiveStory({storyIds:[story.id]})
        if (!res) notification("Не удалось переместить в архив",'warning');
        else if ("description" in res) notification(`Ошибка при перемещении в архив:\n${res.description}`,'warning');
        else {
            if(onSuccess) await onSuccess()
            notification('Новость перемещена в архив')
        }
    }

    const handleUnArchive = async () => {
        const res = await unarchiveStory({storyIds:[story.id]})
        if (!res) notification("Не удалось вернуть новость",'warning');
        else if ("description" in res) notification(`Ошибка при возвращении новости:\n${res.description}`,'warning');
        else {
            if(onSuccess) await onSuccess()
            notification('Новость возвращена','success')
        }
    }

    const handleDelete = async () => {
        const res = await deleteStory({storyIds:[story.id]})
        if (!res) notification("Не удалось удалить новость",'warning');
        else if ("description" in res) notification(`Ошибка при удалении новости:\n${res.description}`,'warning');
        else {
            if(onSuccess) await onSuccess()
            notification('Новость удалена','success')
        }
    }

    return {handlePublish,handleUnpublish,handleArchive,handleUnArchive,handleDelete}
}