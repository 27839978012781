import axios from 'axios';
import ErrorResponse from "types/error-response";
import Story, { NewStory } from "types/story";

export default async (
  data: NewStory,
  images: File[],
  draft?: boolean
): Promise<Story | null | ErrorResponse> => {
  const token = localStorage.getItem("token");
  if (!token) return null;

  try {
    // Формируем URL
    const API_URL = new URL(process.env.REACT_APP_API_URL || '');
    API_URL.pathname += "stories";
    if (draft) API_URL.searchParams.set('draft', 'true');

    // Создаём FormData
    const formData = new FormData();

    // Добавляем JSON-данные с MIME-типом application/json
    formData.append(
      "story",
      new Blob([JSON.stringify(data)], { type: "application/json" })
    );

    // Добавляем изображения с MIME-типами (определяются автоматически File API)
    images.forEach((image, i) => {
      formData.append(`image${i + 1}`, image, image.name);
    });

    // Отправляем запрос с axios
    const response = await axios.post<Story>(
      API_URL.toString(),
      formData,
      {
        headers: {
          "X-Auth-Token": token,
          "Content-Type": "multipart/form-data", // Опционально, axios определяет автоматически
        },
      }
    );

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      // Обрабатываем ошибку ответа
      const errorData: ErrorResponse = error.response.data as ErrorResponse;
      return errorData;
    }
    console.error("Ошибка при выполнении запроса:", error);
    return null;
  }
};
