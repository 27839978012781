import { AnimatePresence, motion } from 'framer-motion'
import React, { DetailedHTMLProps, HTMLAttributes } from 'react'

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>,HTMLDivElement> {
    placeholder?:string
    image?:string | null
}

const Photo = (props: Props) => {
  const {placeholder,image} = props
    return (
    <div className='photo-placeholder' {...props} 
    style={{
        ...props.style,
        borderWidth:image ? 0 : 1
    }}
    >
        <AnimatePresence>
            {image && <motion.img
            key={image}
            // initial={{opacity:0,scale:0.8}}
            // animate={{opacity:1,scale:1}}
            // exit={{opacity:0,scale:0.8}}
            src={image}/>}
        </AnimatePresence>
        {placeholder && 
            <>
            <span>{placeholder}</span>
            <i/>
            <i/>
            <i/>
            <i/>
            </>
        }
    </div>
  )
}

export default Photo