import axios from "axios";
import ErrorResponce from "types/error-response";
import OrdersFilters from "types/orders-filters";
import OrdersResponse from "types/orders-responce";
import appendFiltersToUrl from "utils/append-filters-to-url";

export default async (
  filters: OrdersFilters,
  page: number,
  signal: AbortSignal
): Promise<OrdersResponse | null | ErrorResponce | 'canceled'> => {
  const token = localStorage.getItem("token");
  if (!token) return null;

  try {
    const API_URL = new URL(process.env.REACT_APP_API_URL as string);
    API_URL.pathname += "analytics/orders";

    appendFiltersToUrl(API_URL, filters, page);

    const response = await axios.get<OrdersResponse>(API_URL.toString(), {
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": token,
      },
      signal, // Передаем signal в запрос
    });

    return response.data; // Возвращаем данные
  } catch (error: any) {
    // Если запрос был отменён
    if (error.name === "CanceledError") {
      return 'canceled'
    } else {
      console.error("Request error:", error.message || error);
    }

    return null;
  }
};
