import { AnimatePresence, HTMLMotionProps, motion } from 'framer-motion'
import React, { useState } from 'react'
import Icon, { IconType } from './Icon'

interface Props extends HTMLMotionProps<"button"> {
  errorIf?:boolean
  loading?:boolean
  'data-black'?:boolean
  'data-white'?:boolean
  'data-red'?:boolean
  icon?:IconType
  'show-loading'?:boolean
  handlePromise?:() => Promise<any>
}

const Button = ({
  handlePromise,
  icon: defaultIcon,
  errorIf,
  "show-loading": showLoading,
  ...domProps // остальные пропсы
}: Props) => {
  const [isShaking, setIsShaking] = useState(false)
  const [loading, setloading] = useState(false)
  const icon:IconType = showLoading ? (loading ? 'loadingDefault' : defaultIcon) : defaultIcon
  return (
    <motion.button
    onPointerDown={(e) => {
        if(errorIf || domProps.disabled) setIsShaking(true)
        if(domProps.onPointerDown) domProps.onPointerDown(e)
        setTimeout(() => setIsShaking(false), 500)
    }
    }
    onClick={async (e) => {
      if(domProps.onClick) {
        domProps.onClick(e)
      }
      if(handlePromise) {
        setloading(true)
        await handlePromise()
        setloading(false)
      }
    }}
    animate={isShaking ? { x: [-10, 10, -10, 10, -10, 0], transition:{duration:0.4} } : {x:0}}
    whileTap={isShaking ? {} : {scale:0.95}}
    transition={{duration:0.01}}
    {...domProps}
    className={`${domProps.className ?? ''}`}
    >
        {domProps.children as React.ReactNode}
        {/* {props.loading && <span className='btn-loading-icon'><Icon icon='loading'/></span>} */}
        {icon && 
          <Icon icon={icon}/>
        }
    </motion.button>
  )
}

export default Button