import { Dispatch, SetStateAction, useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/ru"; // Подключаем русский язык
import Icon from "UI/Icon";
import styles from "../TransactionsDatePicker.module.css";
import Button from "UI/Button";
import { checkDatePosition } from "../date-presets";
import isBetween from 'dayjs/plugin/isBetween'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import DateRange from "types/date-range";
dayjs.extend(isBetween)
dayjs.extend(customParseFormat)

// Устанавливаем локаль на русский язык
dayjs.locale("ru");

type Props = {
    displayDate: Dayjs;
    range: DateRange;
    setRange: Dispatch<SetStateAction<DateRange>>;
    selectedPreset: string;
    setInputDate: (d:Dayjs) => void
    setStartDate?:boolean
    setEndDate?:boolean
};
const f = (d: Dayjs) => d.format("DD.MM.YY");

const TransactionsCalendar = ({ displayDate, range, selectedPreset, setRange ,setEndDate,setStartDate,setInputDate}: Props) => {
    const [currentDate, setCurrentDate] = useState(displayDate);
    const [previewRange, setPreviewRange] = useState<DateRange | null>(null); // Состояние для предпросмотра диапазона
    const [inputValue, setInputValue] = useState(f(displayDate))
    const [inInput, setinInput] = useState(false)
    // Получаем текущий месяц и год
    const month = currentDate.format("MMMM");
    const year = currentDate.format("YYYY");

    // Названия дней недели
    const weekDays = ["пн", "вт", "ср", "чт", "пт", "сб", "вс"];

    // Функция для перехода к предыдущему месяцу
    const handlePreviousMonth = () => {
        setCurrentDate(currentDate.subtract(1, "month"));
    };

    // Функция для перехода к следующему месяцу
    const handleNextMonth = () => {
        setCurrentDate(currentDate.add(1, "month"));
    };

    // Генерируем сетку дней для текущего месяца, разбитую по строкам
    const getCalendarRows = () => {
        const daysInMonth = currentDate.daysInMonth();
        const startDayOfWeek = currentDate.startOf("month").day() || 7; // Позиция первого дня недели

        const daysArray = [];

        // Добавляем пустые дни для выравнивания первой строки
        for (let i = 1; i < startDayOfWeek; i++) {
            daysArray.push({ date: null });
        }

        // Добавляем дни с датой
        for (let day = 1; day <= daysInMonth; day++) {
            const date = currentDate.date(day);
            daysArray.push({ date });
        }

        // Разбиваем на строки по 7 элементов для каждой недели
        const rows = [];
        for (let i = 0; i < daysArray.length; i += 7) {
            rows.push(daysArray.slice(i, i + 7));
        }
        return rows;
    };

    const rows = getCalendarRows();

    useEffect(() => {
        setCurrentDate(displayDate);
    }, [selectedPreset,range]);
    useEffect(() => {
        if(!inInput) setInputValue(f(displayDate))
    }, [range])
    // Функция для обработки наведения на день
    const handleMouseEnter = (day: Dayjs | null) => {
        if(!day) return
        if (setEndDate) {
             // Если начальная дата уже выбрана, устанавливаем предпросмотр диапазона
             setPreviewRange({
                start: range.start,
                end: day.isBefore(range.start) ? range.start.endOf('day') : day.endOf('day'),
            });   
        } else if(setStartDate) {
            setPreviewRange({
                start: day.isAfter(range.end) ? range.end.startOf('day') : day.startOf('day'),
                end: range.end,
            });   
        }
    };

    // Функция для установки выбранного диапазона
    const handleDayClick = (day: Dayjs | null) => {
        if(!day || (!setEndDate && !setStartDate)) return
        if (range.start && previewRange) {
            setRange({
                start: previewRange.start,
                end: previewRange.end,
            });
            setPreviewRange(null); // Сброс предпросмотра после выбора
        } else {
            // Устанавливаем только начальную дату
            setRange({ start: day, end: day });
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        if(newValue.length === 9) return
        console.log("🚀 ~ handleInputChange ~ newValue.length:", newValue.length)
        // Проверяем, удаляет ли пользователь точку
        if (inputValue.length > newValue.length && inputValue[newValue.length] === ".") {
            // Если точка удаляется, убираем последний символ
            setInputValue(newValue.slice(0, -1));
            return;
        }
    
        // Удаляем все нечисловые символы
        let value = newValue.replace(/\D/g, "");
    
        // Форматируем значение, добавляя точки
        if (value.length > 2) value = `${value.slice(0, 2)}.${value.slice(2)}`;
        if (value.length > 5) value = `${value.slice(0, 5)}.${value.slice(5)}`;
    
        // Обновляем состояние
        setInputValue(value);
    
        // Если значение пустое, сбрасываем дату
        if (value.length === 0) {
            // setInputDate(null);
            return;
        }
    
        // Попробуем разобрать значение
        let [day, month, year] = value.split(".")
        day = day.length < 2 ? '0' + day : day

        console.log("🚀 ~ handleInputChange ~ day, month, year:", day, month, year)
    
        // Обновляем диапазон, если дата корректна
        console.log("🚀 ~ handleInputChange ~",`${day}.${month || currentDate.format('MM')}.${year || currentDate.format('YY')}`)
        const parsedDate = dayjs(`${day}.${month || currentDate.format('MM')}.${year || currentDate.format('YY')}`, "DD.MM.YY");
        console.log("🚀 ~ handleInputChange ~ parsedDate.isValid():", parsedDate.isValid())
        console.log("🚀 ~ handleInputChange ~ parsedDate:", parsedDate)
        if (parsedDate.isValid()) {
            setInputDate(parsedDate);
        }
    };
    
    
    
    

    return (
        <div className={styles.calendarContainer}>
            <input
                className={styles.calendarInput}
                value={inputValue}
                onChange={handleInputChange}
                type="text" placeholder="Select date"
                onFocus={() => setinInput(true)}
                onBlur={() => setinInput(false)}
                />
            <div className={styles.calendarBodyContainer}>
                <div className={styles.calendarHead}>
                    <Button onClick={handlePreviousMonth}>
                        <Icon icon="arrowLeft" />
                    </Button>
                    <p>{month} {year}</p>
                    <Button onClick={handleNextMonth}>
                        <Icon icon="arrowLeft" style={{ transform: "rotate(180deg)" }} />
                    </Button>
                </div>
                <div className={styles.calendarBody} onMouseLeave={() => setPreviewRange(null)}>
                    <div className={`${styles.calendarBodyRow} ${styles.weekDays}`}>
                        {weekDays.map((day, index) => (
                            <span key={index}>{day}</span>
                        ))}
                    </div>
                    {rows.map((week, rowIndex) => (
                        <div key={rowIndex} className={styles.calendarBodyRow}>
                            {week.map((dayData, dayIndex) => {
                                let postitionStyle = "";
                                if (dayData.date) {
                                    const datePosition = checkDatePosition(previewRange || range, dayData.date);
                                    switch (datePosition) {
                                        case "end":
                                        case "start":
                                            postitionStyle = styles.edgeRange;
                                            break;
                                        case "between":
                                            postitionStyle = styles.beetweenRange;
                                    }
                                }
                                return (
                                    <span
                                        className={`${postitionStyle}`}
                                        key={dayIndex}
                                        onMouseEnter={() => handleMouseEnter(dayData.date)} // Обработчик наведения
                                        onClick={() => handleDayClick(dayData.date)} // Обработчик клика
                                    >
                                        {dayData.date ? dayData.date.date() : ""}
                                    </span>
                                );
                            })}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TransactionsCalendar;
