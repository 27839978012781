import ErrorResponce from "types/error-response";
import Story from "types/story";
interface Responce {
  items: Story[];
  total: number;
  page: number;
  size: number;
  totalPages: number;
  merchantId: string;
}

  export default async (page:number,size:number): Promise<Responce | null | ErrorResponce> => {
    const token = localStorage.getItem('token')
    if(!token) return null
    try {
      const API_URL = new URL(process.env.REACT_APP_API_URL as string);
      API_URL.pathname += "stories";
      API_URL.searchParams.append('page',String(page))
      API_URL.searchParams.append('size',String(size))

      const responce = await fetch(API_URL, {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": token,
        },
        method: "get",
      });
      return await responce.json();
    } catch (error) {
      console.log("🚀 ~ error:", error);
      return null;
    }
  };
  