import ErrorResponce from "types/error-response";
import LoyaltyCardSettings from "types/loyalty-card-settings";

  export default async (): Promise<LoyaltyCardSettings | null | ErrorResponce> => {
    const token = localStorage.getItem('token')
    if(!token) return null
    
    try {
      const API_URL = new URL(process.env.REACT_APP_API_URL as string);
      API_URL.pathname += "loyalty-card-settings/banner-url";

      const responce = await fetch(API_URL, {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": token,
        },
        method: 'DELETE',
      });
      return await responce.json();
    } catch (error) {
      console.log("🚀 ~ error:", error);
      return null;
    }
  };
