import styles from '../TransactionsTable.module.css'

const TransactionRowPlaceHolder = () => {
  return (
    <tr className={styles.tableRowPlaceholder}>
      <td>
        <p>------</p>
        <span>------</span>
      </td>
      <td>
      <div>
      <p>--------</p>
      </div>

      </td>
      <td>
      <p>-------------</p>
      </td>
      <td>
      <p>-------------</p>
      </td>
      <td>
      <p>----------</p>

      </td>
      <td>
      <p>--------</p>

      </td>
      <td>
      <p>-------</p>
      </td>
      <td></td>
    </tr>
  );
};


export default TransactionRowPlaceHolder;
