import { AnimatePresence, motion } from "framer-motion"
import { useEffect, useState } from "react"
import Icon, { IconType } from "UI/Icon"
import { v4 } from "uuid"

type Notification = {
    id:string
    icon?:IconType
    text:string
}

export const notification = (text:Notification['text'],icon?:Notification['icon']) => {
    const event = new CustomEvent<Notification>('notification',{
        detail:{icon,text,id:v4()}
    })
    document.dispatchEvent(event)
}

export default () => {
    const [notifications, setNotifications] = useState<Notification[]>([])

    useEffect(() => {
        const handleEvent = (e:Event) => {
            const {detail} = e as CustomEvent<Notification>
            setNotifications(prev => [...prev,detail])
        }
        document.addEventListener('notification',handleEvent)
        return () => {
            document.removeEventListener('notification',handleEvent)
        }
    }, [])

    const NotificationContainer = (
        <ul className="notification-container">
            <AnimatePresence >
            {notifications.map(({icon,text,id},i) => {
                const top = 15 + i*10 + i*38
                return (
                    <motion.li 
                    key={id}
                    layoutId={id}
                    initial={{x:'-50%',y:'-100%',top:0}}
                    animate={{x:'-50%',y:'0%',top}}
                    exit={{opacity:0,transition:{duration:1}}}
                    onAnimationComplete={() => {
                        setTimeout(() => {
                            setNotifications(prev => prev.filter(n => n.id !== id))
                        }, 4000);
                    }}
                    layout className="custom-notification">
                        <output>
                            {icon && <Icon icon={icon}/>}
                            <p>{text}</p>
                        </output>
                    </motion.li>
                )
            })}
            </AnimatePresence>
        </ul>
    )
    return {NotificationContainer}
}