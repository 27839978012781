import Pica from 'pica';

/**
 * Масштабирует и обрезает изображение в режиме cover.
 * @param file - Исходный файл изображения.
 * @param width - Ширина рамки.
 * @param height - Высота рамки.
 * @returns Promise<File> - Обработанный файл.
 */
export const resizeAndCropImage = async (
  file: File,
  width: number,
  height: number
): Promise<File> => {
  const pica = new Pica();
  const imageBitmap = await createImageBitmap(file);

  const originalWidth = imageBitmap.width;
  const originalHeight = imageBitmap.height;

  // Вычисляем коэффициент масштабирования для режима cover
  const scale = Math.max(width / originalWidth, height / originalHeight);

  const scaledWidth = Math.round(originalWidth * scale);
  const scaledHeight = Math.round(originalHeight * scale);

  // Создаем промежуточный canvas для масштабирования
  const intermediateCanvas = document.createElement('canvas');
  intermediateCanvas.width = scaledWidth;
  intermediateCanvas.height = scaledHeight;

  // Создаем конечный canvas для обрезки
  const outputCanvas = document.createElement('canvas');
  outputCanvas.width = width;
  outputCanvas.height = height;

  // Масштабируем изображение
  await pica.resize(imageBitmap, intermediateCanvas);

  const dx = (scaledWidth - width) / 2; // Смещение для центрирования
  const dy = (scaledHeight - height) / 2;

  // Рисуем центральную часть на выходном canvas
  const ctx = outputCanvas.getContext('2d');
  if (!ctx) {
    throw new Error('Canvas context not available');
  }

  ctx.drawImage(
    intermediateCanvas,
    dx, // Смещение по x
    dy, // Смещение по y
    width, // Ширина обрезки
    height, // Высота обрезки
    0, // Координата на выходном canvas по x
    0, // Координата на выходном canvas по y
    width,
    height
  );

  // Возвращаем новый файл
  return new Promise((resolve) => {
    outputCanvas.toBlob((blob) => {
      if (blob) {
        const resizedFile = new File([blob], file.name, { type: blob.type });
        resolve(resizedFile);
      }
    }, 'image/jpeg');
  });
};
