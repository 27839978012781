import React, { useEffect } from 'react'
import { useNavigate,useNavigationType,useRoutes } from 'react-router-dom'

type Props = {
    to:string
}

const Redicrect = ({to}: Props) => {
  const navigate = useNavigate()
  const navigationType = useNavigationType();
  const isGoingBack = navigationType === "POP";

  useEffect(() => {
    if(isGoingBack) navigate(-1)
    else navigate(to)
  }, [])
    return (
    <></>
  )
}

export default Redicrect