import React, { useContext, useEffect, useState } from "react";
import styles from "../TransactionsInfoBlocks.module.css";
import formatNum from "utils/format-num";
import InfoBlock from "./InfoBlock";
import StatsPurchases from "types/stats-purchases";
import getOrdersStats from "api/analytics/get-orders-stats";
import { TransactionsContext } from "../../../Transactions";
import { toast } from "react-toastify";
type Props = {};

const InfoBlockPurchases = (props: Props) => {
  const [stats, setStats] = useState<StatsPurchases | null>(null);
  const { filters } = useContext(TransactionsContext);
  useEffect(() => {
    setStats(null);
    getOrdersStats(filters).then((res) => {
      if (!res) toast.error("Не удалось получить статистику по покупкам");
      else if ("description" in res)
        toast.error(
          `Ошибка при получении статистики по покупкам:\n${res.description}`
        );
      else setStats(res);
    });
  }, [filters]);
  return (
    <div className={styles.blocksWrapper} data-loading={!stats}>
      <InfoBlock>
        <h3 className={styles.blockTitle}>транзакции</h3>
        <p className={styles.blockValue}>{formatNum(stats?.ordersCount)}</p>
        <p className={styles.blockDesc}>
          {formatNum(stats?.uniqueCustomersCount)} уникальных покупателей
        </p>
      </InfoBlock>
      <InfoBlock>
        <h3 className={styles.blockTitle}>Выручка</h3>
        <p className={styles.blockValue}>
          {formatNum(stats?.revenue)}{" "}
          <span className={styles.blockCurrency}>₽</span>
        </p>
        <p className={styles.blockDesc}>
          Средний чек {formatNum(stats?.avgCheck)} ₽
        </p>
      </InfoBlock>
      <InfoBlock>
        <div className="flex">
          <h3 className={`${styles.blockTitle}`}>
            выручка от покупателя
          </h3>
          <i className={styles.blockHint}>
            <span className={styles.hintBody}>
              Средняя выручка от покупателя
            </span>
          </i>
        </div>
        <p className={styles.blockValue}>
          {formatNum(stats?.revenuePerUniqueCustomer)}{" "}
          <span className={styles.blockCurrency}>₽</span>
        </p>
        <p className={styles.blockDesc}>Частота покупок {stats?.ordersFreq}</p>
      </InfoBlock>
      <InfoBlock>
        <h3 className={styles.blockTitle}>Новые пользователи</h3>
        <p className={styles.blockValue}>+{formatNum(stats?.newCustomers)}</p>
        <p className={styles.blockDesc}>
          {formatNum(stats?.issuedLoyaltyCardCount)} новых карт лояльности
        </p>
      </InfoBlock>
    </div>
  );
};

export default InfoBlockPurchases;
