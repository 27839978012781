import { useContext, useEffect } from "react";
import styles from "./LoyaltyCard.module.css";
import mainStyles from "../../Card&News.module.css";
import descImage from "assets/images/loyalty-card.svg";
import Button from "UI/Button";
import LoyaltyCardSettings from "./components/LoyaltyCardSettings";
import LoyaltyCardPreview from "./components/LoyaltyCardPreview";
import appContext from "utils/app-context";
import { toast } from "react-toastify";

const LoyaltyCard = () => {
  const {LoyaltyCardService} = useContext(appContext)
  const {loading,SaveSettings,ischanged,error,setError,RestoreSettings} = LoyaltyCardService
  useEffect(() => {
    if(error){
      toast.warning(error)
      setError(null)
    }
    return () => {
      RestoreSettings()
    }
  }, [error])
  return (
    <div className={styles.page} data-loading={loading}>
      <div className={mainStyles.description}>
        <img src={descImage} alt="" />
        <div className={mainStyles.descriptionTextBlock}>
          <h1 className={mainStyles.descriptionTitle}>
            Ваша уникальная карта лояльности{" "}
          </h1>
          <p className={mainStyles.descriptionText}>
            Настройте внешний вид карты лояльности всего за два простых шага.
            Выберите цвет, который будет соответствовать вашему бренду, и
            добавьте фирменное изображение. Это позволит создать яркое и
            запоминающееся представление вашего бренда на главном экране
            приложения.
          </p>
        </div>
      </div>
      <div style={{width:'100%'}}>
      <hr style={{marginBottom:20}}/>
      <div className="flex" style={{justifyContent:'space-between',width:'100%'}}>
        <h2 className={styles.descriptionTitle}>Настройка карты лояльности</h2>
        <Button disabled={!ischanged} data-black handlePromise={SaveSettings} icon={undefined} show-loading>Сохранить</Button>
      </div>
      </div>
      <div className={styles.cardSettingsPreview}>
        <LoyaltyCardSettings/>
        <LoyaltyCardPreview/>
      </div>
    </div>
  );
};

export default LoyaltyCard;
