import getAddresses from "api/analytics/get-addresses"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import StoreAddress from "types/store-address"

export default () => {
  const [adresses, setadresses] = useState<StoreAddress[] | null>(null)
  const [loading, setloading] = useState(true)
  const updateAdresses = async () => {
    setloading(true)
    const res = await getAddresses()
    
    if (!res) toast.error('Не удалось получить адреса')
    else if('description' in res) toast.error(`Ошибка при получении адресов:\n${res.description}`)
    else setadresses(res)
  
    setloading(false)
  }
  useEffect(() => {
    updateAdresses()
  }, [])
  return {loading,adresses}
}