import React, { useState } from "react";

type Props = {
  value: boolean;
  setValue: (b:boolean) => void;
};

const Switch = ({ setValue, value: initV }: Props) => {
  const [value, setvalue] = useState(initV);

    const handleClick =() => {
        setvalue(!value)
        setValue(!value)
    }

  return (
    <button className="switch-body" data-checked={value} onClick={handleClick}>
      <span className="switch-circle" />
    </button>
  );
};

export default Switch;
