import React, { useContext, useState } from "react";
import Icon from "./Icon";
import { AnimatePresence, motion } from "framer-motion";
import appContext from "utils/app-context";

type Props = {};

const TopBar = (props: Props) => {
  const [opened, setopened] = useState(false);
  const { handleLogout, user } = useContext(appContext);
  return (
    <section className="top-bar">
      <button className="top-bar-drop-down" onClick={() => setopened(!opened)}>
        <p>{user?.merchantName}</p>
        <motion.span animate={{ rotate: opened ? 0 : 180 }}>
          <Icon icon="arrowTop" />
        </motion.span>
      </button>
      <AnimatePresence>
        {opened && (
          <motion.ul
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className="drop-down-body"
          >
            {/* <li>
              <Icon icon="menuProfile" />
              Профиль
            </li> */}
            <li onClick={handleLogout}>
              <Icon icon="menuExit" />
              Выйти
            </li>
          </motion.ul>
        )}
      </AnimatePresence>
    </section>
  );
};

export default TopBar;
