import React, { useContext, useEffect, useRef, useState } from "react";
import Button from "UI/Button";
import Icon from "UI/Icon";
import styles from "./StoryPage.module.css";
import { useNavigate, useParams } from "react-router-dom";
import StoryPageSettings from "./components/StoryPageSettings";
import StoryPagePreview from "./components/StoryPagePreview";
import { FileWithPath } from "react-dropzone/.";
import Story, { NewStory, StoryAspectRatio, StoryStatus } from "types/story";
import { v4 } from "uuid";
import appContext from "utils/app-context";
import createStory from "api/stories/create-story";
import { toast } from "react-toastify";
import { notification } from "hooks/use-notifications";
import editStory from "api/stories/edit-story";
import { DropZoneFile } from "UI/FilesDropZone";
import deleteStoryImages from "api/stories/delete-story-images";
import { diff } from "deep-diff";
import useStoryActions from "hooks/use-story-actions";

export type NewsItemData = NewStory & {
  whiteLabelPhoto: File | null;
};

const StoryPage = () => {
  let { storyID } = useParams();
  const {
    StoriesService: {
      archive,
      stories,
      error,
      setError,
      setStories,
      loadStories,
    },
  } = useContext(appContext);
  const [isDraft, setisDraft] = useState(false);
  const [images, setImages] = useState<DropZoneFile[]>([]);
  const initialImages = useRef<DropZoneFile[]>([]);
  const [newsItem, setNewsItem] = useState<NewsItemData | Story>({
    title: "",
    content: "",
    aspectRatio: StoryAspectRatio.FOUR_TO_FIVE,
    whiteLabelPhoto: null,
  });
  const nav = useNavigate();
  const pageHeader = useRef<HTMLDivElement>(null);
  const { handlePublish } = useStoryActions(newsItem as Story);
  useEffect(() => {
    const handleScroll = (e: Event) => {
      if (!pageHeader.current) return;
      const { top } = pageHeader.current.getBoundingClientRect();
      const c = styles.isSticky;
      const contains = pageHeader.current.classList.contains(c);
      if (!top && !contains) {
        pageHeader.current.classList.add(c);
      } else if (top && contains) {
        pageHeader.current.classList.remove(c);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const readyToCreate = !isDraft
    ? newsItem.title.length
    : newsItem.title.length && newsItem.content.length && images.length;

  const isPublished =
    "status" in newsItem && newsItem.status === StoryStatus.PUBLISHED;

  const handleCreateStory = async () => {
    let res: Awaited<ReturnType<typeof createStory>>;
    const files = images.filter(({ file }) => file).map(({ file }) => file);
    res = await createStory(newsItem, files as File[], !isDraft);
    if (!res) {
      notification(
        `Не удалось ${!isDraft ? "создать" : "опубликовать"} новость`,
        "warning"
      );
    } else if ("description" in res) {
      notification(
        `Ошибка при ${!isDraft ? "создании" : "публикации"} новости:\n${
          res.description
        }`,
        "warning"
      );
    } else {
      notification(
        `Новость ${!isDraft ? "создана" : "опубликована"}`,
        "success"
      );
      nav(-1);
      setTimeout(() => {
        setStories((prev) => {
          if (!res || "description" in res) return null;
          if (!prev) return null;
          return [res, ...prev];
        });
      }, 600);
    }
  };

  const handleEditStory = async () => {
    if ("id" in newsItem) {
      if (initialImages.current.length && diff(initialImages.current, images)) {
        await deleteStoryImages({
          images: initialImages.current.map(({ image }) => image),
          storyId: newsItem.id,
        });
      }
      const files = images.filter(({ file }) => file).map(({ file }) => file);
      const res = await editStory(newsItem, files as File[]);
      if (!res) {
        notification(`Не удалось сохранить новость`, "warning");
      } else if ("description" in res) {
        notification(
          `Ошибка при сохранении новости:\n${res.description}`,
          "warning"
        );
      } else {
        notification(`Новость сохранена`, "success");
        if (isDraft && !isPublished) await handlePublish(true);
        await loadStories();
        nav(-1);
      }
    }
  };

  useEffect(() => {
    if (!storyID) return;
    if (error) {
      notification("Не удалось загрузить новость", "warning");
      setError(null);
    } else if (archive && stories) {
      const queriedStory = [...archive, ...stories].find(
        ({ id }) => String(id) === storyID
      );
      if (queriedStory) {
        setNewsItem({ ...queriedStory, whiteLabelPhoto: null });
        const images = queriedStory.images.map(({ imageUrl }) => ({
          file: null,
          image: imageUrl,
        }));
        setImages(images);
        if (queriedStory.status === StoryStatus.PUBLISHED) setisDraft(true);
        initialImages.current = images;
      }
    }
  }, [archive, stories]);

  return (
    <div className={styles.page}>
      <div className={styles.header} ref={pageHeader}>
        <h1>{storyID ? "Редактировать" : "Создать"} новость</h1>
        <div>
          <Button onClick={() => nav(-1)} data-white>
            Отмена
          </Button>
          <Button
            data-black
            handlePromise={storyID ? handleEditStory : handleCreateStory}
            icon={undefined}
            show-loading
            disabled={!readyToCreate}
          >
            {!isDraft
              ? storyID
                ? "Сохранить"
                : "Создать"
              : storyID
              ? isPublished
                ? "Сохранить"
                : "Сохранить и опубликовать"
              : "Опубликовать"}
          </Button>
        </div>
      </div>
      <div className={styles.pageContent}>
        <StoryPageSettings
          setStory={setNewsItem}
          story={newsItem}
          isDraft={isDraft}
          setIsdraft={setisDraft}
          setImages={setImages}
          images={images}
        />

        <StoryPagePreview newsItem={newsItem} images={images} />
      </div>
    </div>
  );
};

export default StoryPage;
