import Icon from "UI/Icon"
import AnalyticsRoutes from "./pages/Analytics/Analytics.routes"
import SwipAIRoutes from "./pages/SwipAI/SwipAI.routes"
import RouteItem from "types/route-item"
import Redicrect from "UI/Redicrect"
import MainPage from "./MainPage"
import CardNewsRoutes from "./pages/Card&News/Card&News.routes"

const routes:RouteItem[] = [
    {
        route:{
            path:'',
            element:<Redicrect to="analytics"/>,
        },
        title:''
    },
    // {
    //     route:{
    //         path:'swip-ai',
    //         element:<MainPage routes={SwipAIRoutes} title="SWiP AI"/>,
    //         children: SwipAIRoutes.map(({route}) => route)
    //     },
    //     title:'SWiP AI',
    //     navBarIcon:<Icon icon="navigationSwipAi"/>
    // },
    {
        route:{
            path:'analytics',
            element:<MainPage routes={AnalyticsRoutes} title="Аналитика"/>,
            children: AnalyticsRoutes.map(({route}) => route)
        },
        title:'Аналитика',
        navBarIcon:<Icon icon="navigationAnalytics"/>
    },
    {
        route:{
            path:'card&stories',
            element:<MainPage routes={CardNewsRoutes} title="Карта и новости"/>,
            children: CardNewsRoutes.map(({route}) => route)
        },
        title:'Карта и Медиа',
        navBarIcon:<Icon icon="navigationCardNews"/>
    },
    
    
]
export default routes