export default interface Story {
  id: number;
  createdAt: number;
  updatedAt: number;
  displayOrderIndex: number;
  merchantId: string;
  title: string;
  content: string;
  images: Image[];
  video?: string;
  publishedAt: number | null;
  archivedAt: number | null;
  status: StoryStatus;
  aspectRatio:StoryAspectRatio
}
interface Image {
  orderIndex: number;
  imageUrl: string;
}
export enum StoryStatus { 
    UNPUBLISHED = "UNPUBLISHED",
    PUBLISHED = "PUBLISHED",
    ARCHIVED = "ARCHIVED",
    DELETED = "DELETED",
  }
type StoryStatusDisplayType = Record<StoryStatus,string>

export const StoryStatusDisplay:StoryStatusDisplayType = {
  ARCHIVED:'Архив',
  DELETED:'Удалено',
  PUBLISHED:'Опубликована',
  UNPUBLISHED:'Не опубликована'
}

export enum StoryAspectRatio {
  ONE_TO_ONE = 'ONE_TO_ONE',
  FOUR_TO_FIVE = 'FOUR_TO_FIVE'
}

export type NewStory = Omit<Story,'id' | 'archivedAt' | 'createdAt' | 'publishedAt' | 'updatedAt' | 'status' | 'images' | 'merchantId' | 'displayOrderIndex'>