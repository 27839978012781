import { PaymentMethod } from "types/orders-responce";

export default [
    {
        title:'Другое',
        value:PaymentMethod.ALTERNATIVE
    },
    {
        title:'СБП',
        value:PaymentMethod.SBP
    },
    {
        title:'Привязанная карта',
        value:PaymentMethod.CLOUDPAYMENTS
    },
]