import ErrorResponce from "types/error-response";

export interface AuthResponse {
  token: string;
  refreshToken: string;
  refreshPeriod: number;
}
export default async (email:string,pass:string):Promise<AuthResponse | null | ErrorResponce> => {
  try {
    const API_URL = new URL(process.env.REACT_APP_API_URL as string)
    API_URL.pathname += "auth";
    const responce = await fetch(API_URL,{
        headers:{'Content-Type':'application/json'},
        method:'POST',
        body:JSON.stringify({username:email,password:pass})
    })
    return await responce.json()
  } catch (error) {
    console.log("🚀 ~ error:", error)
    return null
  }
};
