import ErrorResponce from "types/error-response";
import OrdersFilters from "types/orders-filters";
import StatsModules from "types/stats-modules";
import appendFiltersToUrl from "utils/append-filters-to-url";

  export default async (filters:OrdersFilters): Promise<StatsModules | null | ErrorResponce> => {
    const token = localStorage.getItem('token')
    if(!token) return null
    try {
      const API_URL = new URL(process.env.REACT_APP_API_URL as string);
      API_URL.pathname += "analytics/order-module-stats";

      appendFiltersToUrl(API_URL,filters,1,true)

      const responce = await fetch(API_URL, {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": token,
        },
        method: "GET",
      });
      return await responce.json();
    } catch (error) {
      console.log("🚀 ~ error:", error);
      return null;
    }
  };
  