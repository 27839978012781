import {
  AnimatePresence,
  motion,
  Reorder,
} from "framer-motion";
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import {
  DropzoneOptions,
  FileWithPath,
  useDropzone,
} from "react-dropzone";
import { toast } from "react-toastify";
import Button from "UI/Button";
import Icon from "UI/Icon";
import { resizeAndCropImage } from "utils/image-utils";

export type DropZoneFile = {
  file: File | null;
  image: string;
};

interface Props extends DropzoneOptions {
  files: DropZoneFile[];
  setFiles: Dispatch<SetStateAction<DropZoneFile[]>>
  dimentions?: { h: number; w: number };
  ReorderNOTAllowed?:boolean
  canRemoveRemoteFiles?:boolean
}

const FilesDropZone = ({ files, setFiles, dimentions,ReorderNOTAllowed,canRemoveRemoteFiles, ...options }: Props) => {

  const hasRemoteFiles = files.find(({file}) => !file)
  const ReorderAllowed = !ReorderNOTAllowed && !hasRemoteFiles
  const [loading, setLoading] = useState(false)

  const onDrop = useCallback(async (acceptedFiles: FileWithPath[]) => {
    setLoading(true)
    if (dimentions) {
      const { h, w } = dimentions;
      acceptedFiles = await Promise.all(
        acceptedFiles.map(async (file) => {
          const newFile = await resizeAndCropImage(file, w, h);
          return newFile;
        })
      );
    }

    setFiles((prev) => {
      let updatedFiles = [
        ...prev,
        ...acceptedFiles.map((f) => ({ file: f, image: URL.createObjectURL(f) })),
      ];

      // Удаляем файлы, где file === null, но есть image
      updatedFiles = updatedFiles.filter((f) => f.file !== null);

      const limit = options.maxFiles;
      if (limit && updatedFiles.length > limit) {
        const rejectedFiles = updatedFiles.splice(limit, updatedFiles.length - limit);
        rejectedFiles.forEach(({file}) =>
          toast.warning(
            `Файл ${(file as File).name} не был загружен так как превышен лимит (${limit} файлов)`
          )
        );
      }

      return updatedFiles;
    });
    setLoading(false)
  }, [dimentions, options.maxFiles, setFiles]);

  const { getRootProps, getInputProps, isDragActive, open, fileRejections } =
    useDropzone({
      onDrop,
      ...options,
      maxFiles: undefined,
    });

  useEffect(() => {
    fileRejections.forEach(({ errors, file }) => {
      errors.forEach(({ code, message }) => {
        switch (code) {
          case "file-too-large":
            toast.warning(
              `Размер файла ${file.name} превышает лимит.\nФайл не был загружен`
            );
            break;

          default:
            toast.warning(
              <>
                Ошибка загрузки файла {file.name} <br /> code: {code} <br />{" "}
                message: {message}
              </>
            );
            break;
        }
      });
    });
  }, [fileRejections]);

  const animateProps = {
    initial: { opacity: 0, y: 50 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, scale: 0.5 },
    transition: { delay: 0.1 },
  };

  return (
    <div className={`drop-zone-container ${isDragActive && "drag-over"}`}>
      {loading && <Icon icon="loadingDefault" className="drop-zone-loading"/>}
      <input type="text" {...getInputProps()} />
      <div className="drop-zone-wrapper" {...getRootProps()}>
        <Reorder.Group
          axis="x"
          onReorder={setFiles}
          values={files}
          className="drop-zone"
        >
          <AnimatePresence mode="popLayout">
            {files.length ? (
              files.map((f) => {
                const { file, image } = f;
                return (
                  <Reorder.Item
                    value={f}
                    key={image}
                    className="uploaded-photo-wrapper"
                    {...animateProps}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    drag={ReorderAllowed}
                    style={{cursor:!ReorderAllowed ? 'default' : 'grab'}}
                    whileDrag={{cursor:!ReorderAllowed ? 'default' : 'grabbing'}}
                  >
                    <div className="uploaded-photo">
                      <img src={image} alt="Uploaded preview" />
                      {(canRemoveRemoteFiles || file) && <button
                        onClick={() =>
                          setFiles((prevFiles) =>
                            prevFiles.filter((prefF) => f !== prefF)
                          )
                        }
                      />}
                    </div>
                  </Reorder.Item>
                );
              })
            ) : (
              <motion.div
                {...animateProps}
                key={1}
                layout={false}
                className="placeholder"
              >
                <Icon icon="uploadFile" />
                <span>Перетяните или нажмите сюда</span>
              </motion.div>
            )}
          </AnimatePresence>
        </Reorder.Group>
      </div>
      <div className="drop-zone-info">
        <span>
          {files.length} из {options.maxFiles} фото
        </span>
        <span>|</span>
        <Button>
          <span style={{ color: "#353945" }} onClick={open}>
            Выбрать файл{options.maxFiles && options.maxFiles > 1 ? 'ы' : ''}
          </span>
        </Button>
        {!!files.length && (
          <>
            <span>|</span>
            <Button>
              <span style={{ color: "#353945" }} onClick={() => setFiles([])}>
                Удалить {files.length > 1 && "все"}
              </span>
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default FilesDropZone;
