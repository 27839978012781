import React, { useContext, useEffect, useState } from "react";
import styles from "../TransactionsInfoBlocks.module.css";
import formatNum from "utils/format-num";
import Icon from "UI/Icon";
import InfoBlock from "./InfoBlock";
import StatsModules from "types/stats-modules";
import getModuleStats from "api/analytics/get-module-stats";
import { toast } from "react-toastify";
import { TransactionsContext } from "../../../Transactions";
type Props = {};

const InfoBlockModules = (props: Props) => {
  const [stats, setStats] = useState<StatsModules | null>(null)
  const {filters} = useContext(TransactionsContext)
  useEffect(() => {
    setStats(null)
    getModuleStats(filters).then(res => {
      if (!res) toast.error("Не удалось получить статистику по модулям");
      else if ("description" in res) toast.error(`Ошибка при получении статистики по модулям:\n${res.description}`);
      else setStats(res);
    })
  }, [filters])
  return (
    <div className={styles.blocksWrapper} data-loading={!stats}>
      <InfoBlock>
        <div className="flex">
          <h3 className={`${styles.blockTitle} ${styles.blockTitle__green}`}>
            Выручка от AI
          </h3>
          <i className={styles.blockHint}>
            <span className={styles.hintBody}>Добавить подсказку</span>
          </i>
        </div>
        <p className={styles.blockValue}>
          +{formatNum(stats?.aiOrdersRevenue)} <span className={styles.blockCurrency}>₽</span>
        </p>
        <p className={styles.blockDesc}>{formatNum(stats?.aiOrdersCount)} транзакций</p>
        <div className={styles.blockIcon}><Icon icon="transactionsStatAiRevenue"/></div>
      </InfoBlock>

      <InfoBlock>
        <div className="flex">
          <h3 className={`${styles.blockTitle} ${styles.blockTitle__yellow}`}>
            Выручка от Рефералов
          </h3>

          <i className={styles.blockHint}>
            <span className={styles.hintBody}>Добавить подсказку</span>
          </i>
        </div>
        <p className={styles.blockValue}>
          +{formatNum(stats?.referralOrdersRevenue)} <span className={styles.blockCurrency}>₽</span>
        </p>
        <p className={styles.blockDesc}>
          {formatNum(stats?.referralOrdersCount)} транзакций • {formatNum(stats?.referrerCount)} рефереров • {formatNum(stats?.referralCount)} рефералов
        </p>
        <div className={styles.blockIcon}><Icon icon="transactionsStatRefRevenue"/></div>
      </InfoBlock>
    </div>
  );
};

export default InfoBlockModules;
