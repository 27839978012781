import RouteItem from "types/route-item"
import Redicrect from "UI/Redicrect"
import LoyaltyCard from "./pages/LoyaltyCard/LoyaltyCard"
import Stories from "./pages/Stories/Stories"
import StoryPage from "./pages/CreateNewsItem/StoryPage"

const routes:RouteItem[] = [
    {
        route:{
            path:'',
            element:<Redicrect to="loyalty-card"/>,
        },
        title:''
    },
    {
        route:{
            path:'loyalty-card',
            element:<LoyaltyCard/>
        },
        title:'Карта лояльности'
    },
    {
        route:{
            path:'stories',
            element:<Stories/>,
            children:[
                
            ]
        },
        title:'Медиа'
    },
    {
        route:{
            path:'create-story',
            element:<StoryPage/>
        },
        title:'Создать новость',
        hideTopBar:true
    },
    {
        route:{
            path:'story/:storyID',
            element:<StoryPage/>
        },
        title:'Редактировать новость',
        hideTopBar:true
    },
    
]
export default routes