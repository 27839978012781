import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "../StoryPage.module.css";
import Switch from "UI/Switch";
import Button from "UI/Button";
import FilesDropZone, { DropZoneFile } from "UI/FilesDropZone";
import { NewsItemData } from "../StoryPage";
import Story, { StoryAspectRatio, StoryStatus } from "types/story";
import { useParams } from "react-router-dom";

const NEWS_TITLE_MAX_SIZE = 35;
const NEWS_TEXT_MAX_SIZE = 512;

type Props = {
  setStory: Dispatch<SetStateAction<NewsItemData | Story>>;
  story: NewsItemData | Story;
  isDraft: boolean;
  setIsdraft: Dispatch<SetStateAction<boolean>>;
  setImages: Dispatch<SetStateAction<DropZoneFile[]>>;
  images: DropZoneFile[];
};

const StoryPageSettings = ({
  setStory,
  story,
  isDraft,
  setIsdraft,
  setImages,
  images,
}: Props) => {
  const { aspectRatio, content, title } = story;
  let { storyID } = useParams();

  const isSquare = aspectRatio === StoryAspectRatio.ONE_TO_ONE;
  const isPublished =
    "status" in story && story.status === StoryStatus.PUBLISHED;
  return (
    <div className={styles.newsSettingsBody}>
      {!isPublished && (
        <div className={styles.newsSettingsBlock}>
          <div className={styles.newsSettingsFragment}>
            <h2>Публикация</h2>
          </div>
          <div className={styles.newsSettingsFragment}>
            <div className={styles.publicationFragmentHeader}>
              <Switch setValue={(v) => setIsdraft(v)} value={false} />
              <h2 className={isDraft ? "" : styles.disabledTitle}>
                Опубликовать сразу
              </h2>
            </div>
            <p style={{ paddingLeft: 34 }}>
              Новость будет сразу опубликована в приложении. Если опция
              отключена, потребуется ручная публикация новости в разделе
              «Текущие новости».
            </p>
          </div>
        </div>
      )}
      <div className={styles.newsSettingsFragment} style={{ gap: 30 }}>
        <h2>Название новости</h2>
        <div className={styles.newsTitleInputContainer}>
          <input
            type="text"
            value={title}
            onChange={(e) => {
              let v = e.target.value;
              if (v.length > NEWS_TITLE_MAX_SIZE)
                v = v.slice(0, NEWS_TITLE_MAX_SIZE);
              setStory((prev) => ({ ...prev, title: v }));
            }}
            placeholder="Добавьте название, например: Новое сезонное меню"
          />
          <p>
            {title.length} / {NEWS_TITLE_MAX_SIZE}
          </p>
        </div>
      </div>
      <div className={styles.newsSettingsBlock}>
        <div className={styles.newsSettingsFragment}>
          <h2>Фото</h2>
          <p>
            Добавьте от 1 до 5 изображений новости. <br />
            Форматы: JPG, JPEG. Максимум: 1 Мб
          </p>
        </div>
        <div className={styles.newsSettingsFragment}>
          <div className={styles.photoModes}>
            <Button
              onClick={() =>
                setStory((prev) => ({
                  ...prev,
                  aspectRatio: StoryAspectRatio.FOUR_TO_FIVE,
                }))
              }
              className={!isSquare ? styles.active : ""}
            >
              Вертикальные – 4:5
            </Button>
            <Button
              onClick={() =>
                setStory((prev) => ({
                  ...prev,
                  aspectRatio: StoryAspectRatio.ONE_TO_ONE,
                }))
              }
              className={isSquare ? styles.active : ""}
            >
              Квадратные 1:1
            </Button>
          </div>
          <p>
            Рекомендуемый размер: 1280×{isSquare ? 1280 : 1600} пикселей.
            Изображения другого размера будут обрезаны автоматически.
            {storyID && ' Каждая новая загрузка удаляет ранее загруженные фотографии.'}
          </p>
        </div>
        <FilesDropZone
          maxFiles={5}
          accept={{ "image/*": [".jpg", ".jpeg"] }}
          maxSize={1 * 1024 * 1024}
          dimentions={{ w: 1280, h: isSquare ? 1280 : 1600 }}
          files={images}
          setFiles={setImages}
        />
      </div>
      <div className={styles.newsSettingsBlock}>
        <div className={styles.newsSettingsFragment} style={{ gap: 30 }}>
          <h2>Текст новости</h2>
          <div className={styles.newsTitleInputContainer}>
            <textarea
              rows={9}
              value={content}
              onChange={(e) => {
                let v = e.target.value;
                if (v.length > NEWS_TEXT_MAX_SIZE)
                  v = v.slice(0, NEWS_TEXT_MAX_SIZE);
                setStory((prev) => ({ ...prev, content: v }));
              }}
              placeholder="Добавьте текст новости"
            />
            <p>
              {content.length} / {NEWS_TEXT_MAX_SIZE}
            </p>
          </div>
        </div>
      </div>
      <div className={styles.newsSettingsBlock}>
        <div className={styles.newsSettingsFragment}>
          <h2 className={styles.whiteLabelTag}>Превью для заставки новости</h2>
          <p>
            Форматы: JPG, JPEG. Максимум: 0.5 Мб <br />
            Размер: 1280×410 пикселей.
          </p>
        </div>
        <FilesDropZone
          maxFiles={1}
          accept={{ "image/*": [".jpg", ".jpeg"] }}
          maxSize={0.5 * 1024 * 1024}
          files={[]}
          setFiles={() => {}}
        />
      </div>
    </div>
  );
};

export default StoryPageSettings;
