import { OrderType } from "types/orders-responce";

export default [
    {
        title:'На кассе',
        value:OrderType.QR
    },
    {
        title:'За столом',
        value:OrderType.PRE_CHECK
    },
    {
        title:'Самовывоз',
        value:OrderType.PRE_ORDER
    },
    {
        title:'Доставка',
        value:OrderType.DELIVERY
    }
]