import Story, { StoryStatus } from "types/story";

export default (story: Story): string | null => {
    let errors: string | null = null;

    const isTextMissing = !story.title || !story.content;
    const isImageMissing = !story.images || story.images.length === 0;

        if (isTextMissing && isImageMissing) {
            errors = "отсутствуют текст и изображение новости";
        } else if (isTextMissing) {
            errors = "отсутствует текст новости";
        } else if (isImageMissing) {
            errors = "отсутствует изображение новости";
    }

    return errors;
};
