import React, { useState } from "react";
import styles from "../StoryPage.module.css";
import Button from "UI/Button";
import newsSkeleton from "assets/images/news-skeleton-placeholder.svg";
import { motion } from "framer-motion";
import { NewsItemData } from "../StoryPage";
import Photo from "UI/Photo";
import Story, { StoryAspectRatio } from "types/story";
import { DropZoneFile } from "UI/FilesDropZone";

type Props = {
  newsItem: NewsItemData | Story;
  images:DropZoneFile[]
};

const StoryPagePreview = ({ newsItem ,images}: Props) => {
  const [previewMode, setpreviewMode] = useState(0);
  const {aspectRatio} = newsItem
  const newsPhoto = !images[0]
    ? null
    : images[0].file
      ? URL.createObjectURL(images[0].file)
      : images[0].image
      
  const whiteLabelPhoto = 'whiteLabelPhoto' in newsItem && newsItem.whiteLabelPhoto
    ? URL.createObjectURL(newsItem?.whiteLabelPhoto)
    : null;

  const displayContent = newsItem?.content?.length ? newsItem.content : "Текст новости"

  return (
    <div className={styles.NewsItemPrevievWrapper}>
      <div className={styles.NewsItemPrevievBody}>
        <h2 className={styles.NewsItemPrevievBodyTitle}>Предпросмотр </h2>
        <div className={styles.photoModes}>
          <Button
            onClick={() => setpreviewMode(0)}
            className={!previewMode ? styles.active : ""}
          >
            Новость
          </Button>
          <Button
            onClick={() => setpreviewMode(1)}
            className={previewMode ? styles.active : ""}
          >
            Баннер White Label
          </Button>
        </div>
        {!previewMode ? (
          <div className={styles.PreviewCard}>
            <Photo
              key={1}
              placeholder="Фото"
              image={newsPhoto}
              style={{
                height:aspectRatio === StoryAspectRatio.ONE_TO_ONE ? 320 : 400,
                borderBottomLeftRadius:0,
                borderBottomRightRadius:0
              }}
            />
            <div className={styles.PreviewCardBody}>
              <img src={newsSkeleton} alt="" />
              <h3>{newsItem?.title?.length ? newsItem.title : "Заголовок"}</h3>
              <p dangerouslySetInnerHTML={{__html:displayContent.split('\n').join('<br>')}}></p>
            </div>
          </div>
        ) : (
          <motion.div className={styles.whiteLabelPreview}>
            <Photo
              key={2}
              placeholder="Ваш баннер"
              image={whiteLabelPhoto}
            />
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default StoryPagePreview;
