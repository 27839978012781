import getOrderDetails from "api/analytics/get-order-details";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import OrderDetails, { DiscountCalcResult } from "types/order-details";
import styles from "../TransactionsTable.module.css";
import { Tags } from "types/orders-responce";
import Icon from "UI/Icon";
import { OrderTags } from "./TransactionRow";
import orderFilterTypes from "utils/order-filter-types";
import paymentMethodsFilterTypes from "utils/payment-methods-filter-types";
import dayjs from "dayjs";
import useHint from "hooks/use-hint";
type Props = {
  id: string;
};

const TransactionOverView = ({ id }: Props) => {
  let [_, setSearchParams] = useSearchParams();
  const [order, setorder] = useState<OrderDetails | null>(null);

  useEffect(() => {
    getOrderDetails(id).then((res) => {
      if (!res) toast.error("Не удалось получить детали транзакции");
      else if ("description" in res)
        toast.error(`Ошибка при деталей транзакции:\n${res.description}`);
      else setorder(res);
    });

    return () => {
      setSearchParams({});
    };
  }, []);

  const orderType = orderFilterTypes.find(
    ({ value }) => value === order?.orderType
  )?.title;
  const paymentMethod = paymentMethodsFilterTypes.find(
    ({ value }) => value === order?.paymentMethod
  )?.title;
  const date = dayjs(order?.createdAt);
  let points = "";
  if (order?.awardPoints) points = `+${order?.awardPoints}`;
  if (order?.withdrawPoints)
    points +=
      points.length > 0
        ? ` / -${order?.withdrawPoints}`
        : `-${order?.withdrawPoints}`;
  return (
    <div className={styles.overviewBody} data-loading={!order}>
      <div className={styles.overviewBodyContent}>
        <h2 className={styles.overviewTitle}>Транзакция</h2>
        <div className={styles.overviewStatus}>
          <i />
          <p>Успешно завершена</p>
        </div>
        <table className={styles.overviewTable}>
          <tbody>
            <tr>
              <td>Теги</td>
              <td>
                <div className={styles.tagList}>
                  {order && (
                    <OrderTags
                      rating={order.rating}
                      tags={order.tags}
                      customerId={order.customerId}
                      orderId={order.id}
                      bottomAIHint
                    />
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <td>Покупатель</td>
              <td>{order?.phone || "-"}</td>
            </tr>
            <tr>
              <td>ID покупателя</td>
              <td>{order?.customerId}</td>
            </tr>
            <tr>
              <td>ID транзакции</td>
              <td>{order?.id}</td>
            </tr>
            <tr>
              <td>Акции</td>
              <td>
                {!order?.discountCalcResults.length && "-"}
                <ul className={styles.discountList}>
                  {order?.discountCalcResults.map((discount, i) => (
                    <RowDiscount data={discount} key={i} />
                  ))}
                </ul>
              </td>
            </tr>
            <tr>
              <td>Тип заказа</td>
              <td >{orderType} <span className={styles.hintColor}>{order?.orderNumber}</span></td>
            </tr>
            <tr>
              <td>Способ оплаты</td>
              <td>{paymentMethod}</td>
            </tr>
            <tr>
              <td>Дата</td>
              <td>
                {date.format("DD.MM.YYYY")} {date.format("HH:MM:ss")}
              </td>
            </tr>
          </tbody>
        </table>
        <div className={styles.overviewSubTitle}>
          <h3>Детализация</h3>
        </div>
        <table className={styles.overviewTable}>
          <tbody>
            <tr>
              <td>Итоговая сумма</td>
              <td>{order?.total.toFixed(2)} ₽</td>
            </tr>
            <tr>
              <td>Сумма заказа</td>
              <td>{order?.totalOriginal.toFixed(2)} ₽</td>
            </tr>
            <tr>
              <td>Скидка</td>
              <td>
                {order?.discountRub ? (
                  <>
                    {order?.discountRub.toFixed(2)} ₽{" "}
                    <span className={styles.hintColor}>{11}%</span>
                  </>
                ) : (
                  "-"
                )}
              </td>
            </tr>
            <tr>
              <td>Баллов начислено/списано</td>
              <td>{points || "-"}</td>
            </tr>
            <tr>
              <td>Оценка</td>
              <td>
                {order?.rating ? (
                  <div className={styles.tagRating}>
                    <Icon icon="tagStar" />
                    {order.rating}
                  </div>
                ) : (
                  "-"
                )}
              </td>
            </tr>
            <tr>
              <td>Комментарий</td>
              <td>{order?.feedback || "-"}</td>
            </tr>
          </tbody>
        </table>
        <div className={styles.overviewSubTitle}>
          <h3>Позиции в чеке</h3>
        </div>
        <table
          className={`${styles.overviewTable} ${styles.overviewTablePurchases}`}
        >
          <thead>
            <tr>
              <td colSpan={2}>
                <span className={styles.hintColor}>Товар</span>
              </td>
              <td>
                <span className={styles.hintColor}>Цена</span>
              </td>
            </tr>
          </thead>
          <tbody>
            {order?.purchases.map(({ name, price, quantity, id }) => (
              <tr key={id}>
                <td>{quantity} ×</td>
                <td>{name}</td>
                <td>
                  <span className={styles.hintColor}>{price.toFixed(2)} ₽</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const RowDiscount = ({
  data: { programId, programName, discount },
}: {
  data: DiscountCalcResult;
}) => {
  const t = "Скопировать ID акции"
  const { hide, hint, show,setTextContent } = useHint(t,{top:0});
  
  const handleClick = () => {
    setTextContent('Скопировано ✓')
    navigator.clipboard.writeText(String(programId))
    setTimeout(() => setTextContent(t),1000)
  }
  return (
    <li onClick={handleClick}>
      {hint}
      <div onPointerEnter={show} onPointerLeave={hide}>
      {programName} {discount}% <span>(ID {programId})</span> 
      </div>
    </li>
  );
};

export default TransactionOverView;
