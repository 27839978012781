export default interface OrdersResponse {
  items: Order[];
  total: number;
  page: number;
  size: number;
  totalPages: number;
}

export interface Order {
  id: string;
  merchantId: string;
  createdAt: string;
  storeId: string;
  address: string;
  timeZone: string;
  orderStatus: OrderStatus;
  orderType: OrderType;
  fulfillmentStatus: null | FulFillmentStatus;
  orderNumber: null | string;
  paymentMethod: PaymentMethod;
  totalOriginal: number;
  discountRub: null;
  discountPercent: null;
  awardPoints: number;
  withdrawPoints: number;
  total: number;
  customerId: string;
  newCustomer:boolean
  phone: null | string;
  rating: number;
  tags: Tags[] | null;
}
export enum OrderType { 
  OT_EMPTY = "OT_EMPTY",
  QR = "QR",
  PRE_CHECK = "PRE_CHECK",
  PRE_ORDER = "PRE_ORDER",
  DELIVERY = "DELIVERY",
}

export enum OrderStatus {
  OS_EMPTY = "OS_EMPTY",
  OPEN = "OPEN",
  CLOSED = "CLOSED",
  REFUND = "REFUND",
  REVERSE = "REVERSE",
}

export enum PaymentMethod {
  ALTERNATIVE = "ALTERNATIVE",
  PM_EMPTY = "PM_EMPTY",
  CLOUDPAYMENTS = "CLOUDPAYMENTS",
  SBP = "SBP",
}

export enum Tags {
  RATING = "RATING",
  REFERRAL = "REFERRAL",
  REFERRER = "REFERRER",
  T_EMPTY = "T_EMPTY",
  AI_DISCOUNT = "AI_DISCOUNT",
}

export enum FulFillmentStatus {
  FT_EMPTY = 'FT_EMPTY',
  CREATED = 'CREATED',
  READY = 'READY',
  RECEIVED = 'RECEIVED',
  CANCELLED = 'CANCELLED',
  WITH_COURIER = 'WITH_COURIER',
  DELIVERED = 'DELIVERED',
}