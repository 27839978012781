import React from "react";
import dayjs from "dayjs";
import datePresets, { PresetKeys } from "../date-presets";
import styles from "../TransactionsDatePicker.module.css";
import DateRange from "types/date-range";

interface Props {
  selectedPreset: string;
  onPresetSelect: (presetTitle: PresetKeys, range: DateRange) => void;
}

const TransactionsCalendarPresets = ({ selectedPreset, onPresetSelect }: Props) => {
  return (
    <ul className={styles.calendarPresets}>
      {Object.keys(datePresets).map((key) => {
        const { title, getRange } = datePresets[key as PresetKeys]
        const isActive = key === selectedPreset;
        return (
          <li
            key={title}
            className={`${styles.presetItem} ${isActive ? styles.activePreset : ""}`}
            onClick={() => onPresetSelect(key as PresetKeys, getRange())}
          >
            {title}
          </li>
        );
      })}
    </ul>
  );
};

export default TransactionsCalendarPresets;
