import ErrorResponce from "types/error-response";
import LoyaltyCardSettings from "types/loyalty-card-settings";
import loyaltyCardDeleteBanner from "./loyalty-card-delete-banner";
import { DropZoneFile } from "UI/FilesDropZone";

  type Params = {
    colorInHex:string
    file:DropZoneFile | null
  }

  export default async (params?:Params): Promise<LoyaltyCardSettings | null | ErrorResponce> => {
    const token = localStorage.getItem('token')
    if(!token) return null
    
    try {
      const API_URL = new URL(process.env.REACT_APP_API_URL as string);
      API_URL.pathname += "loyalty-card-settings";
      const data = new FormData()
      
      const headers:HeadersInit = {
        "X-Auth-Token": token,
      }
     
      if(params) {
        if(params.file === null) {
          await loyaltyCardDeleteBanner()
        } else {
          data.append('file',params.file.file || '')
        }
        API_URL.searchParams.set('colorInHex',params.colorInHex)
        // headers['Content-Type'] = 'multipart/form-data'
      }

      const responce = await fetch(API_URL, {
        headers,
        body: params ? data : undefined,
        method: params ? "PUT" : "GET",
      });
      return await responce.json();
    } catch (error) {
      console.log("🚀 ~ error:", error);
      return null;
    }
  };
