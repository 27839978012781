import React, { useEffect, useState } from "react";
import styles from "./TransactionsInfoBlocks.module.css";
import { animate, AnimatePresence, motion } from "framer-motion";
import tabs from "./tabs";
import Button from "UI/Button";
import getOrdersStats from "api/analytics/get-orders-stats";
type Props = {};

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const TransactionsStatistics = (props: Props) => {
  const [currentTab, setcurrentTab] = useState(0);
  const [isAnimated, setisAnimated] = useState(false)
  return (
    <div className={styles.body} style={{overflow: isAnimated ? 'hidden' : 'unset'}}>
      <div className={styles.tabs}>
        {tabs.map(({ title }, i) => (
          <Button
            key={i}
            className={`${styles.tab} ${i === currentTab && styles.currentTab}`}
            onClick={() => setcurrentTab(i)}
          >
            {title}
          </Button>
        ))}
      </div>
      <AnimatePresence mode="wait" initial={false}>
        <motion.div
        onAnimationStart={() => setisAnimated(true)}
        onAnimationComplete={() => setisAnimated(false)}
        initial={{x:30,opacity:0}}
        animate={{x:0,opacity:1}}
        exit={{x:-30,opacity:0}}
        transition={{ease:'easeInOut',duration:0.1}}
        key={currentTab}
        style={{width:'100%'}}
        >
          {tabs[currentTab].component}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default TransactionsStatistics;
