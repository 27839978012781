import React from 'react'
import { Outlet, useLocation, useRoutes } from 'react-router-dom'
import NavigationBar from 'UI/NavigationBar'
import PageWrapper from 'UI/PageWrapper'
import routes from './Main.routes'
import styles from './Main.module.css'
import TopBar from 'UI/TopBar'
import { AnimatePresence } from 'framer-motion'
type Props = {}

const Main = (props: Props) => {
  const location = useLocation()
  const element = useRoutes(routes.map(({ route }) => route));

   // Extract the base path for Analytics
   const rootPath = "/" + location.pathname.split("/")[2];
   if(!element) return null
  return (
    <PageWrapper className={styles.mainPage}>
      <NavigationBar routesList={routes}/>
        <div className={styles.pageContent}>
          <TopBar/>
          <AnimatePresence mode="wait">
          <PageWrapper data-vertical key={rootPath} className='section-wrapper'>
            {React.cloneElement(element)}
          </PageWrapper>
          </AnimatePresence>
        </div>
    </PageWrapper>
  )
}

export default Main