import { ForwardRefComponent, HTMLMotionProps, motion, Transition } from "framer-motion";
import React, { DetailedHTMLProps, HTMLAttributes, useEffect, useState } from "react";
import { useNavigationType } from "react-router-dom";

interface Props extends HTMLMotionProps<"div"> {
  loading?: boolean;
  onAnimationEnd?: () => void;
  'data-vertical'?:true
}

const transition:Transition = {
  duration: 0.1,
  ease:'easeInOut'
};

const PageWrapper = (props: Props) => {
  const { loading, children, onAnimationEnd } = props;
  const navigationType = useNavigationType();
  const [isAnimated, setisAnimated] = useState(false)
  const isGoingBack = navigationType === "POP";

  let animationProps:HTMLMotionProps<"div"> = {
    initial: {
      opacity: 0,
      translateX: isGoingBack ? -50 : 50,
    },
    animate: { opacity: 1, translateX: 0,transition },
    exit: {
      opacity: 0,
      translateX: isGoingBack ? 50 : -50,
      position:'absolute',
      transition,
    },
  };

  if(props['data-vertical']) animationProps = {
    initial: {
      opacity: 0,
      translateY: isGoingBack ? -50 : 50,
    },
    animate: { opacity: 1, translateY: 0,transition },
    exit: {
      opacity: 0,
      translateY: isGoingBack ? 50 : -50,
      transition,
    },
  }
  useEffect(() => {
    const elem = document.querySelector<HTMLDivElement>('.section-wrapper')
    if(elem) elem.style.overflow = isAnimated ? 'hidden' : 'unset'
    document.body.style.overflowX = isAnimated ? 'hidden' : 'unset'
    window.scrollTo({top:0,behavior:'auto'})
  }, [isAnimated])
  return (
    <motion.div
      onAnimationStart={() => setisAnimated(true)}
      onAnimationComplete={() => {
        setisAnimated(false)
        if(onAnimationEnd) onAnimationEnd()
      }}
      id={window.location.pathname}
      {...animationProps}
      {...props}
      className={`${props.className} page-wrapper`}
    >
      {children}
    </motion.div>
  );
};

export default PageWrapper;
