import ErrorResponce from "types/error-response";
import OrdersFilters from "types/orders-filters";
import StatsPaymentMethods from "types/stats-payment-methods";
import PurchaseStatistics from "types/stats-purchases";
import appendFiltersToUrl from "utils/append-filters-to-url";

  export default async (filters:OrdersFilters): Promise<StatsPaymentMethods | null | ErrorResponce> => {
    const token = localStorage.getItem('token')
    if(!token) return null
    try {
      const API_URL = new URL(process.env.REACT_APP_API_URL as string);
      API_URL.pathname += "analytics/payment-methods-stats";

      appendFiltersToUrl(API_URL,filters,1,true)

      const responce = await fetch(API_URL, {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": token,
        },
        method: "GET",
      });
      return await responce.json();
    } catch (error) {
      console.log("🚀 ~ error:", error);
      return null;
    }
  };
  