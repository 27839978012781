import getOrdersCount from "api/analytics/get-orders-count";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import OrdersFilters from "types/orders-filters";
import formatNum from "utils/format-num";

type Props = {
  filters: OrdersFilters;
};

const TransactionsCounter = ({ filters }: Props) => {
  const [count, setcount] = useState<number | null>(null);
  const abortControllerRef = useRef<AbortController | null>(null);

  useEffect(() => {
    setcount(null);
    // Отменяем предыдущий запрос, если он существует
    const abort = () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    }
    abort()
    // Создаем новый AbortController
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    getOrdersCount(filters, 0, abortController.signal).then((res) => {
      if (res === "canceled") return;

      if (typeof res === "number") {
        setcount(res);
      } else if (!res) {
        toast.error("Не удалось получить количество транзакций");
      } else if ("description" in res) {
        toast.error(
          `Ошибка при получении количества транзакций:\n${res.description}`
        );
      }
    });
    return () => abort()
  }, [filters]);
  return (
    <span data-loading={count === null}>{formatNum(count || 0)} записей</span>
  );
};

export default TransactionsCounter;
