import { title } from "process";
import InfoBlockPurchases from "./components/InfoBlockPurchases";
import InfoBlockPayMethods from "./components/InfoBlockPayMethods";
import InfoBlockOrderTypes from "./components/InfoBlockOrderTypes";
import InfoBlockModules from "./components/InfoBlockModules";

const tabs = [
    {
        title:'Покупки',
        component:<InfoBlockPurchases/>
    },
    {
        title:'Способы оплаты',
        component:<InfoBlockPayMethods/>
    },
    {
        title:'Типы заказа',
        component:<InfoBlockOrderTypes/>
    },
    {
        title:'Модули',
        component:<InfoBlockModules/>
    },
]
export default tabs