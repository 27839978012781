import { AnimatePresence, motion } from "framer-motion";
import { useContext } from "react";
import Button from "./Button";
import Icon from "./Icon";
import appContext from "utils/app-context";
import { bgVariants, bodyVariants } from "utils/modal-animation";

type Props = {};

const Modal = (props: Props) => {
  const { modal, setModal } = useContext(appContext);

  return (
    <AnimatePresence>
      {modal && (
        <motion.div
          {...bgVariants}
          onClick={() => setModal(null)}
          className="modal-bg"
        >
          {modal.modal === undefined ? <motion.div
            {...bodyVariants}
            className="modal-body"
            onClick={(e) => e.stopPropagation()}
          >
            {modal.close && (
              <Button className="modal-close" onClick={() => setModal(null)}>
                <Icon icon="close" />
              </Button>
            )}
            {modal.content}
          </motion.div> : modal.content}
        </motion.div>
      )}
    </AnimatePresence>
  );
};



export default Modal;
