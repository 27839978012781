import React from 'react'
import Icon from './Icon'
import RouteItem from 'types/route-item'
import { Link, useLocation } from 'react-router-dom'

type Props = {
    routesList:RouteItem[]
}

const NavigationBar = ({routesList}: Props) => {
  const location = useLocation()
  const rootPath = location.pathname.split("/")[2];

  return (
    <aside className='navigation'>
        <Link to={'/main'}><Icon icon='logoSwipOffice'/></Link>
        <nav>
            <ul>
              {routesList.map(({title,route,navBarIcon}) => {
                const isCurrent = rootPath === route.path
                if(!title.length || !navBarIcon) return null
                return (
                  <li className={isCurrent ? 'current' : ''} key={title}>
                      <Link to={route.path || ''}>
                      {navBarIcon}
                      {title}</Link>
                  </li>
                )
              })}
            </ul>
        </nav>
    </aside>
  )
}

export default NavigationBar