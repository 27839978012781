// datePresets.ts
import dayjs, { Dayjs } from 'dayjs';
import DateRange from 'types/date-range';

export interface DatePreset {
    title: string;
    getRange: () => DateRange;
    matchesRange: (start: Dayjs, end: Dayjs) => boolean;
}
export type PresetKeys = 'today' | 'yesterday' | 'week' | 'arbitrarily' | 'year' | 'halfYear' | 'quarter' | 'month'
export const datePresets: Record<PresetKeys,DatePreset> = {
    today:{
        title: 'Сегодня',
        getRange: () => {
            const today = dayjs();
            return { start: today.startOf('day'), end: today.endOf('day') };
        },
        matchesRange: (start, end) => {
            const { start: presetStart, end: presetEnd } = datePresets.today.getRange();
            return start.isSame(presetStart) && end.isSame(presetEnd);
        }
    },
    yesterday:{
        title: 'Вчера',
        getRange: () => {
            const yesterday = dayjs().subtract(1, 'day');
            return { start: yesterday.startOf('day'), end: yesterday.endOf('day') };
        },
        matchesRange: (start, end) => {
            const { start: presetStart, end: presetEnd } = datePresets.yesterday.getRange();
            return start.isSame(presetStart) && end.isSame(presetEnd);
        }
    },
    week:{
        title: 'Текущая неделя',
        getRange: () => {
            const today = dayjs();
            return { start: today.startOf('week'), end: today.endOf('week') };
        },
        matchesRange: (start, end) => {
            const { start: presetStart, end: presetEnd } = datePresets.week.getRange();
            return start.isSame(presetStart) && end.isSame(presetEnd);
        }
    },
    month:{
        title: 'Текущий месяц',
        getRange: () => {
            const today = dayjs();
            return { start: today.startOf('month'), end: today.endOf('month') };
        },
        matchesRange: (start, end) => {
            const { start: presetStart, end: presetEnd } = datePresets.month.getRange();
            return start.isSame(presetStart) && end.isSame(presetEnd);
        }
    },
    quarter:{
        title: 'Квартал',
        getRange: () => {
            const today = dayjs();
            const quarterStartMonth = Math.floor(today.month() / 3) * 3;
            const start = today.month(quarterStartMonth).startOf('month');
            const end = start.add(2, 'month').endOf('month');
            return { start, end };
        },
        matchesRange: (start, end) => {
            const { start: presetStart, end: presetEnd } = datePresets.quarter.getRange();
            return start.isSame(presetStart) && end.isSame(presetEnd);
        }
    },
    halfYear:{
        title: 'Полгода',
        getRange: () => {
            const today = dayjs();
            const halfYearStartMonth = today.month() < 6 ? 0 : 6;
            const start = today.month(halfYearStartMonth).startOf('month');
            const end = start.add(5, 'month').endOf('month');
            return { start, end };
        },
        matchesRange: (start, end) => {
            const { start: presetStart, end: presetEnd } = datePresets.halfYear.getRange();
            return start.isSame(presetStart) && end.isSame(presetEnd);
        }
    },
    year:{
        title: 'Год',
        getRange: () => {
            const today = dayjs();
            return { start: today.startOf('year'), end: today.endOf('year') };
        },
        matchesRange: (start, end) => {
            const { start: presetStart, end: presetEnd } = datePresets.year.getRange();
            return start.isSame(presetStart) && end.isSame(presetEnd);
        }
    },
    arbitrarily:{
        title: 'Произвольно',
        getRange: () => {
            // Default range for arbitrary dates
            return { start: dayjs(), end: dayjs() };
        },
        matchesRange: () => false // Always returns false as this is a custom range
    }
}

/**
 * Finds the matching preset title for a given start and end date range.
 * Returns `undefined` if no preset matches.
 */
export const findMatchingPreset = (start: Dayjs, end: Dayjs): PresetKeys => {
    let matchingPreset:PresetKeys = 'arbitrarily'
    for (const key in datePresets) {
       if(datePresets[key as PresetKeys].matchesRange(start,end))
            matchingPreset = key as PresetKeys
    }
    return matchingPreset;
};
export function checkDatePosition(dateRange: DateRange, dateToCheck: Dayjs): 'start' | 'between' | 'end' | 'out' {
    if (dateToCheck.isSame(dateRange.start, 'day')) {
        return 'start';
    } else if (dateToCheck.isSame(dateRange.end, 'day')) {
        return 'end';
    } else if (dateToCheck.isAfter(dateRange.start, 'day') && dateToCheck.isBefore(dateRange.end, 'day')) {
        return 'between';
    } else {
        return 'out';
    }
}
export default datePresets;
