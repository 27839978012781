import { Tags } from "types/orders-responce";

export default [
    {
        title:'AI',
        value:Tags.AI_DISCOUNT
    },
    {
        title:'Реферал',
        value:Tags.REFERRAL
    },
    {
        title:'Реферер',
        value:Tags.REFERRER
    },
    {
        title:'Оценка',
        value:Tags.RATING
    },
]