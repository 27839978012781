import React from 'react'
import styles from "../TransactionsInfoBlocks.module.css";
import { HTMLMotionProps, motion } from 'framer-motion';

type Props = {}

const variants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 }
      }
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 }
      }
    }
  };

const InfoBlock = (props: HTMLMotionProps<"div">) => {
  return (
    <motion.div variants={variants} className={styles.block}>
        {props.children}
    </motion.div>
  )
}

export default InfoBlock