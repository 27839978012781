import ErrorResponce from "types/error-response";

export interface AuthResponse {
  token: string;
  refreshToken: string;
  refreshPeriod: number;
}

export default async (
  refreshToken: string
): Promise<AuthResponse | null | ErrorResponce> => {
  try {
    const API_URL = new URL(process.env.REACT_APP_API_URL as string);
    API_URL.pathname += "auth/token-refresh";
    const responce = await fetch(API_URL, {
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": refreshToken,
      },
      method: "PUT",
    });
    return await responce.json();
  } catch (error) {
    console.log("🚀 ~ error:", error);
    return null;
  }
};
